<template>
  <div class="upsell-products" v-if="getCurrentUpsellProducts.length > 0">
    <h2>{{ $t('Recommended product to add') }}</h2>
    <div v-for="product in getCurrentUpsellProducts" :key="product.sku">
      <div class="sf-product-card">
        <SfCheckbox
          v-model="upsellProducts"
          name="upsell"
          :value="product.sku"
          class="form__element form__checkbox"
        />
        <div
          class="sf-product-card__image-wrapper"
          @mouseover="() => { isHovered = true; }"
          @mouseleave="() => { isHovered = false; }"
        >
          <span
            v-if="product.badges && product.badges.label !== null"
            :key="product.badges.label"
            class="sf-product-card__badges"
            :style="{color: product.badges.textColor, 'background-color': product.badges.bgColor}"
          >
            {{ product.badges.label }}
          </span>
          <SfLink :link="product.link" class="sf-product-card__link">
            <div class="o-product-card__standard-image-wrapper">
              <img
                :src="product.image"
                :alt="product.title"
                :height="100"
                :width="100"
                loading="lazy"
              >
            </div>
          </SfLink>
          <SfBadge
            v-if="product.badges.label"
            class="sf-product-card__badge"
            :class="product.badges.bgColor"
          >
            {{ product.badges.label }}
          </SfBadge>
        </div>
        <div class="sf-product-card__title-wrapper">
          <SfLink :link="product.link" class="sf-product-card__link">
            <h3 class="sf-product-card__title" :title="product.title">
              {{ product.title }}
            </h3>
          </SfLink>
        </div>
        <div class="sf-product-card__buy-button">
          <SfPrice
            v-if="(getFinalPrice(product))"
            class="sf-product-card__price"
            :regular="formatPrice(getFinalPrice(product))"
          />
          <div v-else>
            <div class="sf-price sf-product-card__price">
              <span class="sf-price__regular">{{ $t('Sold Out') }}</span>
            </div>
          </div>
          <div class="sf-price sf-product-card__price strikethrough-price" v-if="getFinalPrice(product) < product.price.regular">
            {{ formatPrice(product.price.regular) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { prepareRelatedQuery } from '@vue-storefront/core/modules/catalog/queries/related';
import SfPrice from '@storefront-ui/vue/src/components/atoms/SfPrice/SfPrice.vue';
import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';
import SfBadge from '@storefront-ui/vue/src/components/atoms/SfBadge/SfBadge.vue';
import SfCheckbox from '@storefront-ui/vue/src/components/molecules/SfCheckbox/SfCheckbox.vue';
import { formatPrice, prepareListingProduct } from '~/theme/helpers';
import config from 'config';

export default {
  name: 'MUpSellProducts',
  components: {
    SfLink,
    SfBadge,
    SfPrice,
    SfCheckbox
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      upsellProducts: []
    };
  },
  computed: {
    ...mapGetters({
      getProductRelated: 'product/getProductRelated',
      getCurrentProduct: 'product/getCurrentProduct'
    }),
    getCurrentUpsellProducts () {
      let products = this.getProductRelated[this.type] || [];
      return products?.map(prepareListingProduct).filter(product => product.isInStock === true);
    },
    productLinks () {
      return this.getCurrentProduct.upsell_sku;
    },
    hasStrikethroughPrice () {
      return this.finalPrice < this.regularPrice
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-load', this.refreshList);
    this.$bus.$on('upsell-add-to-cart', this.addToCart);

    if (config.usePriceTiers) {
      this.$bus.$on('user-after-loggedin', this.refreshList);
      this.$bus.$on('user-after-logout', this.refreshList);
    }

    this.refreshList();
  },
  beforeDestroy () {
    if (config.usePriceTiers) {
      this.$bus.$off('user-after-loggedin', this.refreshList);
      this.$bus.$off('user-after-logout', this.refreshList);
    }
    this.$bus.$off('product-after-load', this.refreshList);
    this.$bus.$off('upsell-add-to-cart', this.addToCart);
  },
  methods: {
    formatPrice (price) {
      return formatPrice(price)
    },
    async addToCart () {
      // Add product to cart
      let product = {};
      if (this.upsellProducts.length) {
        for (let index in this.upsellProducts) {
          product = await this.$store.dispatch('product/single', { options: { sku: this.upsellProducts[index] } });
          if (product.type_id === 'simple') {
            await this.$store.dispatch('cart/addItem', { productToAdd: product });
          } else {
            this.$router.push(`/${product.url_path}`)
          }
        }
      }
      this.upsellProducts = [];
    },
    async refreshList () {
      if (!this.productLinks) {
        this.$store.dispatch('product/related', {
          key: this.type,
          items: []
        });
        return true;
      }
      const sku = this.productLinks.split(',').map((item) => item.trim());
      const relatedProductsQuery = prepareRelatedQuery('sku', sku);
      const response = await this.$store.dispatch('product/findProducts', {
        query: relatedProductsQuery,
        size: 8,
        prefetchGroupProducts: false,
        updateState: false
      });
      if (response) {
        this.$store.dispatch('product/related', {
          key: this.type,
          items: response.items
        });
        this.$forceUpdate();
      }
    },
    getFinalPrice (product) {
      return product?.price?.final || product?.final_price || product?.finalPrice
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.upsell-products {
  h2 {
    font-size: var(--font-size--base);
    margin: var(--spacer-15) 0 var(--spacer-15) 0;
  }
  .sf-product-card {
    display: flex !important;
    align-items: center;
    max-width: 100%;
    padding: var(--spacer-15) 0 0 0;
    flex-direction: row;
    &:hover {
      --product-card-box-shadow: none;
    }
    @include for-mobile {
      position: relative;
      padding: 0;
      padding-left: 135px;
      display: block !important;
      margin: 0 0 var(--spacer-15) 0;
      min-height: var(--spacer-80);
      .sf-checkbox {
        position: absolute;
        left: 0;
        top: calc(50% - 15px)
      }
    }
    .sf-product-card__image-wrapper {
      margin: 0 var(--spacer-10);
      min-width: var(--spacer-100);
      width: var(--spacer-100);
      img {
        display: block;
      }
      @include for-mobile {
        margin: 0 0px;
        position: absolute;
        left: var(--spacer-30);
        top:0;
        width: var(--spacer-80);
        min-width: var(--spacer-80);
        .o-product-card__standard-image-wrapper {
          width: var(--spacer-80);
          img {
            max-width: var(--spacer-80);
            height: auto;
          }
        }
      }
    }
    &__image-wrapper {
      border-radius: 7px;
      overflow: hidden;
    }
    &__title-wrapper {
      --link-text-decoration: none;
      display: flex;
      width: 100%;
    }
    &__title {
      padding: 0;
      margin: 0;
      font-weight: var(--font-weight--medium);
      text-align: left;
      margin-bottom: var(--spacer-xs);
      font-family: var(--font-family--primary);
      font-size: var(--font-size--sm);
      text-transform: uppercase;
    }
    &__link {
      text-align: center;
      .a-image--wrapper {
        display: block;
      }
    }
    &__price {
      --product-card-margin: var(--spacer-xs) var(--spacer-xs);
    }
    &__buy-button {
      --price-regular-color: currentColor;
      --price-special-color: currentColor;
      --price-regular-font-size: var(--font-size--lg);
      --price-regular-font-family: var(--font-family--primary);
      --price-regular-font-weight: var(--font-weight--bold);
      margin-top: var(--spacer-xs);

      .strikethrough-price {
        text-decoration: line-through;
        text-align: right;
      }
    }
    .sf-price {
      text-wrap: nowrap;
      justify-content: end;
      @include for-mobile {
        justify-content: start;
        margin: 0;
      }
      span {
        margin: 0;
      }
    }
    .sf-product-card__buy-button{
      margin: 0;
    }
  }
}
</style>
<style lang="scss">
.upsell-products {
  .sf-product-card {
    .sf-price {
      span {
        margin: 0;
      }
    }
  }
}
</style>
